/* Home Page Styles */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  padding: 0 20px;

  margin-top: -60px; /* Move the container up */
}

.header {
  text-align: center;
  margin-top: 40px;
}

.welcome-message {
  font-size: 3rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.tagline {
  font-size: 1.2rem;
  color: #555;
  font-weight: 300;
  margin-top: 10px;
  letter-spacing: 0.5px;
}

/* Styling for Humor Message */
.humor-message-container {
  position: absolute; /* Allow the container to move freely */
  animation: moveAround 40s ease-in-out infinite; /* Much slower duration (40s) for a smooth effect */
  font-size: 1.4rem; /* Slightly larger font size for emphasis */
  font-weight: 400;
  color: #3c1919;
  text-align: center;
}

@keyframes moveAround {
  0% {
    top: 0;
    left: 0;
    transform: translate(0, 0) rotate(0deg) scale(1); /* Start at top-left corner, no rotation */
  }
  25% {
    top: 0;
    left: 100%;
    transform: translateX(-100%) rotate(45deg) scale(1.15); /* Top-right corner with rotation and scale */
  }
  50% {
    top: 100%;
    left: 100%;
    transform: translate(-100%, -100%) rotate(90deg) scale(1.25); /* Bottom-right corner with more rotation */
  }
  75% {
    top: 100%;
    left: 0;
    transform: translateY(-100%) rotate(135deg) scale(1.15); /* Bottom-left corner with rotation and scale */
  }
  100% {
    top: 0;
    left: 0;
    transform: translate(0, 0) rotate(180deg) scale(1); /* Back to top-left corner with rotation */
  }
}

.humor-message {
  background: rgba(
    255,
    255,
    255,
    0.7
  ); /* Slightly more transparent background */
  padding: 20px;
  border-radius: 18px; /* Larger rounded corners */
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15); /* Larger, softer shadow for a floating effect */
  text-align: center;
  font-family: 'Arial', sans-serif;
  color: #3c1919;
  max-width: 330px;
  margin: auto;
  line-height: 1.8;
  letter-spacing: 0.5px; /* Slight letter spacing for style */
  transition: all 0.4s ease-in-out; /* Smooth transition for hover effect */
}

/* Optional hover effect for interactivity */
.humor-message-container:hover .humor-message {
  transform: scale(1.15) rotate(-5deg); /* Slight zoom and rotation when hovering */
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
  background: rgba(
    255,
    255,
    255,
    0.85
  ); /* Increase background opacity on hover */
}

/* Add some margin below the PersonalityQuiz component */
.PersonalityQuiz {
  margin-top: 40px;
  margin-bottom: 50px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .welcome-message {
    font-size: 2.5rem;
  }

  .tagline {
    font-size: 1rem;
  }

  .humor-message {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .welcome-message {
    font-size: 2rem;
  }

  .tagline {
    font-size: 0.9rem;
  }

  .humor-message {
    font-size: 1rem;
  }
}
