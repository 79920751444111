/* Contact Section */
.contact-section {
  color: #333;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0; /* More padding for a spacious look */
  margin-top: -20vh;
}

/* Content Box */
.contact-content {
  max-width: 800px; /* Increased width for better alignment */
  width: 100%;
  padding: 50px;
  background-color: #fff;
  border-radius: 20px; /* More rounded corners for modern look */
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1); /* Softer shadow for elegance */
  text-align: center;
  animation: colorChange 10s infinite alternate;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  transform: scale(
    0.98
  ); /* Slight initial scale for smoother hover transition */
}

.contact-content:hover {
  transform: scale(1.05); /* Smooth zoom on hover */
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}

/* Heading */
.contact-section h2 {
  font-size: 2.8rem;
  margin-bottom: 20px;
  color: #1a73e8; /* More vibrant blue for the title */
  font-weight: 700; /* Heavier font weight for stronger impact */
  letter-spacing: 1px; /* Slightly increased letter spacing for elegance */
  text-transform: uppercase; /* Uppercase text for professionalism */
  border-bottom: 3px solid #1a73e8; /* Border for added flair */
  display: inline-block; /* Make the border align with the heading */
  padding-bottom: 5px;
}

/* Paragraph Styling */
.contact-section p {
  font-size: 1.3rem;
  line-height: 1.7;
  margin-bottom: 40px;
  color: #555; /* Slightly darker gray for better readability */
  font-weight: 400;
  letter-spacing: 0.5px; /* Slight letter spacing for improved legibility */
}

/* Social Media Icons */
.social-media {
  display: flex;
  justify-content: center;
  gap: 30px; /* Larger gap between icons */
  margin-top: 30px;
}

.social-media a {
  color: #1a73e8;
  font-size: 45px; /* Larger icons for better visibility */
  transition: color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
  text-decoration: none;
  opacity: 0.8; /* Slight opacity for subtle effect */
}

.social-media a:hover {
  color: #1557a1; /* Darker blue for hover effect */
  transform: translateY(-5px); /* Slight lift effect on hover */
  opacity: 1; /* Full opacity when hovered */
}

/* Keyframes for background color change */
@keyframes colorChange {
  0% {
    background-color: #f9f9f9;
  }
  50% {
    background-color: #f1f1f1;
  }
  100% {
    background-color: #e9e9e9;
  }
}

/* Responsive Design for Tablets */
@media (max-width: 768px) {
  .contact-content {
    padding: 40px;
  }

  .contact-section h2 {
    font-size: 2.2rem;
  }

  .contact-section p {
    font-size: 1.1rem;
  }

  .social-media a {
    font-size: 40px; /* Slightly smaller icons for tablets */
  }
}

/* Responsive Design for Mobile Devices */
@media (max-width: 480px) {
  .contact-section h2 {
    font-size: 1.8rem;
  }

  .contact-section p {
    font-size: 1rem;
  }

  .social-media a {
    font-size: 32px; /* Smaller icons for smaller screens */
  }
}
