/* src/components/Projects.css */

.projects-container {
  padding: 20px;
  color: #fff; /* Retain text color for contrast */
}

.projects-heading {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.project-box {
  position: relative;
  overflow: hidden;

  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-box img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the box */
  transition: transform 0.3s ease;
}

.project-box:hover img {
  transform: scale(1.1); /* Slight zoom effect on hover */
}

.project-box:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.7);
}

/* Optional: Hover overlay effect */
.project-box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay */
  opacity: 0;
  transition: opacity 0.3s ease;
}

.project-box:hover::before {
  opacity: 1; /* Show overlay on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
  .projects-heading {
    font-size: 2rem; /* Slightly smaller heading on medium screens */
  }

  .projects-grid {
    grid-template-columns: repeat(
      auto-fill,
      minmax(150px, 1fr)
    ); /* Adjust column width for tablets */
  }
}

@media (max-width: 480px) {
  .projects-heading {
    font-size: 1.5rem; /* Smaller heading on small screens */
  }

  .projects-grid {
    grid-template-columns: repeat(
      auto-fill,
      minmax(100px, 1fr)
    ); /* Adjust column width for mobile devices */
    gap: 10px; /* Reduce gap between boxes */
  }

  .project-box {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3); /* Lighter shadow on smaller screens */
  }
}
