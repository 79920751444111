/* src/pages/About.css */

.about-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  background-color: transparent;
  color: #333;
  font-family: 'Arial', sans-serif;
}

.profile-pic {
  width: 200px; /* Default width */
  height: 200px; /* Default height */
  border-radius: 10px; /* Rounded corners for a square look */
  margin-bottom: 20px;
  position: relative; /* Required for absolute positioning of the border effect */
  overflow: hidden; /* Ensures border effect is within the rounded corners */
}

.profile-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fits well within the profile-pic */
  display: block; /* Removes any extra spacing below the image */
}

.profile-pic::before {
  content: '';
  position: absolute;
  top: -5px; /* Border thickness */
  left: -5px; /* Border thickness */
  width: calc(100% + 10px); /* Adjust for border thickness */
  height: calc(100% + 10px); /* Adjust for border thickness */
  border: 5px solid #0073e6; /* Border color and thickness */
  border-radius: 15px; /* Matches the border-radius of the profile-pic */
  animation: running-border 2s linear infinite; /* Animation effect */
  box-sizing: border-box; /* Ensures border is included in dimensions */
  z-index: 1; /* Border should be above the image */
}

@keyframes running-border {
  0% {
    transform: translateX(-100%) translateY(-100%);
  }
  25% {
    transform: translateX(100%) translateY(-100%);
  }
  50% {
    transform: translateX(100%) translateY(100%);
  }
  75% {
    transform: translateX(-100%) translateY(100%);
  }
  100% {
    transform: translateX(-100%) translateY(-100%);
  }
}

.about-content {
  max-width: 800px;
  text-align: center;
}

.about-heading {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: purple;
  margin-top: -20px;
}

.about-text {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 15px;
  color: #3c1919;
  background: rgba(
    255,
    255,
    255,
    0.8
  ); /* Optional: Adds a white overlay for readability */
  padding: 15px; /* Adds spacing inside the text block */
  border-radius: 10px; /* Optional: Rounds the corners */
  position: relative; /* Ensures this text stays above the background */
  z-index: 2; /* Ensures the text is above the container background */
}

/* Responsive Styles */

/* For tablets and smaller screens */
@media (max-width: 768px) {
  .profile-pic {
    width: 150px; /* Smaller width */
    height: 150px; /* Smaller height */
  }

  .about-heading {
    font-size: 2rem; /* Slightly smaller font size */
  }

  .about-text {
    font-size: 1rem; /* Slightly smaller font size */
  }
}

/* For mobile phones */
@media (max-width: 480px) {
  .profile-pic {
    width: 120px; /* Smaller width */
    height: 120px; /* Smaller height */
  }

  .about-heading {
    font-size: 1.5rem; /* Smaller font size */
  }

  .about-text {
    font-size: 0.9rem; /* Smaller font size */
  }
}
